import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { WalletContext } from '../context/WalletContext';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { account } = useContext(WalletContext); // Access the connected account from WalletContext

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setDropdownOpen(false);
    setMenuOpen(false);
  };

  const handleMiningZoneClick = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-light">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src="https://bighitterss.io/assets/img/resources/footer_logo.png" alt="BigHitterss logo" width={"180"} />
        </a>
        <button 
          className="navbar-toggler d-lg-none" 
          type="button" 
          onClick={toggleMenu} // Toggles the entire menu
          aria-controls="collapsibleNavId" 
          aria-expanded={menuOpen ? "true" : "false"} 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className={`collapse navbar-collapse justify-content-end ${menuOpen ? "show" : ""}`} id="collapsibleNavId">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="dropdownId"
                onClick={toggleDropdown} // Toggle the Trade+ dropdown
                aria-haspopup="true"
                aria-expanded={dropdownOpen ? "true" : "false"}
              >
                Trade +
              </a>
              {dropdownOpen && (
                <div className="dropdown-menu show" aria-labelledby="dropdownId">
                  <Link className="dropdown-item" to="/connect-wallet" onClick={closeMenu}>
                    {account ? 'Connected' : 'Connect Wallet'}
                  </Link>
                  {/* <Link className="dropdown-item" to="/buy-bhts" onClick={closeMenu}>
                    Buy BHTS
                  </Link> */}
                  <Link className="dropdown-item" to="/sell-bhts" onClick={closeMenu}>
                    Sell BHTS
                  </Link>
                </div>
              )}
            </li>
            <li className="nav-item"><a className="nav-link" href="https://utility.clickcart.live/" target="_blank" rel="noopener noreferrer">Utility</a></li>
            <li className="nav-item"><a className="nav-link" href="https://food.clickcart.live/" target="_blank" rel="noopener noreferrer">Food</a></li>
            <li className="nav-item"><a className="nav-link" href="https://movie.clickcart.live/" target="_blank" rel="noopener noreferrer">Movie</a></li>
            <li className="nav-item"><a className="nav-link" href="https://clickcart.live/" target="_blank" rel="noopener noreferrer">Shopping</a></li>
            <li className="nav-item"><a className="nav-link" href="https://holiday.clickcart.live/" target="_blank" rel="noopener noreferrer">Holiday</a></li>
            <li className="nav-item"><a className="nav-link" href="https://gv.clickcart.live/" target="_blank" rel="noopener noreferrer">Gift Voucher</a></li>
            <li className="nav-item">
              <a className="btn btn-outline-success mining-zone" href="https://bighitterss.io/" rel="noopener noreferrer" >Mining Zone</a>
            </li>
            <li className="nav-item">
              <a className="btn btn-outline-success white-paper" href="/BIG HITTER WHITE PAPER.pdf" target="_blank" rel="noopener noreferrer">White Paper</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
