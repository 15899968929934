// src/components/MultiusageTokenSection.js
import React from 'react';
import './MultiusageTokenSection.css'; // Create this CSS file to style your multiusage token section

const MultiusageTokenSection = () => {
  return (
    <section className="multiusage-token-section">
      <h2>A Multiusage Token</h2>
      <p>Earn and burn at the same platform. Use your token to enjoy services like online shopping, food booking, movie booking, paying bills, flight booking, hotel booking, and many more.</p>
      <div className="multiusage-content">
        <div className="card">
          <h3>Shopping Portal</h3>
          <ul>
            <li>Three level inspection system before shipping.</li>
            <li>20+ categories like Lifestyle, Electronics, Sports, Kitchenware, Mobile & IT Accessories to choose products.</li>
            <li>Delivery within 15 days.</li>
          </ul>
        </div>
        <div className="card">
          <h3>Online Holiday</h3>
          <ul>
            <li>Over 70 domestic destinations to choose from.</li>
            <li>More than 2000 hotels for selection.</li>
            <li>Hassle free online booking process.</li>
            <li>Hotels in 3/4/5* categories.</li>
          </ul>
        </div>
        <div className="card">
          <h3>Gift Vouchers</h3>
          <ul>
            <li>Gift Vouchers in categories like Ecommerce, Food, Jewellery.</li>
            <li>Hassle free usage process.</li>
            <li>Maximum benefit in minimum time.</li>
            <li>Instant delivery of the vouchers..</li>
            <li>Save every time you buy anything.</li>
            <li>Offer applied on many brands across categories.</li>
            <li>Brands like Croma, More, Myntra, Book My Show, KFC, Tanishq & many more.</li>
          </ul>
        </div>
        <div className="card">
          <h3>Movie Portal</h3>
          <ul>
            <li>Live & Online Booking System.</li>
            <li>Book tickets in top theaters like</li>
            <li>Carnival, Cinepolis, PVR, Inox & many more..</li>
            <li>Trending movies all the time.</li>
            <li>No convenience charge applied.</li>
          </ul>
        </div>
        <div className="card">
          <h3>Food Portal</h3>
          <ul>
            <li>Top restaurants, cafes & Street food shops of the cities.</li>
            <li>More than 10,000 orders have been successfully placed.</li>
            <li>More than 5,00,000 restaurants from all over India.</li>
            <li>Delivery through trusted delivery partners like.</li>
            
          </ul>
        </div>
        <div className="card">
          <h3>Utility Portal</h3>
          <ul>
            <li>8+ Live services like Flight, Hotel,</li>
            <li>Bus, Prepaid & many more services.</li>
            <li>Option to pay bills of services like credit card, insurance, gas & electricity.</li>
            <li>Best & Finest prices on most of the Services.</li>
            <li>Live & Instant Services.</li>
            <li>Highest accuracy in Transaction.</li>
            <li>Highest in industry.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MultiusageTokenSection;
