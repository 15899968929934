// src/components/MainSection.js
import React from 'react';
import './MainSection.css'; // Create this CSS file to style your main section
import Slider from './Slider'; // Import the Slider component
import MultiusageTokenSection from './MultiusageTokenSection'; // Import the new component
import TimelineSection from './TimelineSection';
import SupportedWallets from './SupportedWallets';

const MainSection = () => {
  const handleLearnMoreClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <section className="main-section">
      <div className="main-image p-3">
        <Slider />
      </div>
      <div className="main-content">
        <div className="card">
          <img src="assets/travel.jpg" alt="Travel" />
          <h2>Travel & Bill Payment</h2>
          <p>"Discover, Journey, Simplify: Your Passport to Seamless Travel and Bill Payment!"</p>
          <button onClick={() => handleLearnMoreClick('https://utility.clickcart.live/')}>Learn More</button>
        </div>
        <div className="card">
          <img src="assets/food.jpg" alt="Food" />
          <h2>Food Portal</h2>
          <p>More than 25,000 restaurants from all over India...</p>
          <button onClick={() => handleLearnMoreClick('https://food.clickcart.live/')}>Learn More</button>
        </div>
        <div className="card">
          <img src="assets/movie.jpg" alt="Movie" />
          <h2>Movie Portal</h2>
          <p>Live & Online Booking System. Book tickets in top theaters...</p>
          <button onClick={() => handleLearnMoreClick('https://movie.clickcart.live/')}>Learn More</button>
        </div>
        <div className="card">
          <img src="assets/holiday.jpg" alt="Holiday" />
          <h2>Online Holiday</h2>
          <p>Escape, Explore, Experience: Your Gateway to Seamless Holiday Booking!</p>
          <button onClick={() => handleLearnMoreClick('https://holiday.clickcart.live/')}>Learn More</button>
        </div>
        <div className="card">
          <img src="assets/shopping.jpg" alt="Shopping" />
          <h2>Shopping</h2>
          <p>Discover, Shop, Enjoy: Your Ultimate Destination for Effortless Online Shopping</p>
          <button onClick={() => handleLearnMoreClick('https://clickcart.live/')}>Learn More</button>
        </div>
      </div>
      <MultiusageTokenSection />
      <TimelineSection />
      <SupportedWallets /> {/* Add the TimelineSection component here */} {/* Add the new section here */}
    </section>
  );
};

export default MainSection;
