import React, { useContext, useState } from 'react';
import './ConnectWallet.css';
import { WalletContext } from '../context/WalletContext';

const ConnectWallet = () => {
  const { account, setAccount } = useContext(WalletContext);
  const [error, setError] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0]);
        setError(null); // Clear any previous errors
      } catch (error) {
        setError('User denied account access');
        console.error(error);
      }
    } else {
      setError('MetaMask not found. Please install MetaMask!');
      console.error('MetaMask not found');
    }
  };

  return (
    <div className='row'>
      <div className='col-lg-4 col-sm-6 offset-lg-4 offset-sm-3 mt-4 mb-4'>
          <div className="gradient-card">
            <div className="card p-4 rounded-5 footer-wrap border-golden pt-5 pb-5 w-100 mb-0">
              <div className="logo-bhts" align="center">
                <img
                  src="https://bighitterss.io/assets/img/resources/footer_logo.png"
                  alt="BHTS Logo"
                  className="img-responsive"
                  width={300}
                />
              </div>
              <div className="header-btn pt-5 pb-2" align="center">
                <button className="btn style1" onClick={connectWallet} >
                {account ? 'Connected' : 'Connect Wallet'}
                  <i className="fas fa-wallet" />
                </button>
                {account && <p> {account}</p>}
              </div>
              {error && <div className="error-message">{error}</div>}
            </div>
          </div>
        </div>
    </div>
  );
};

export default ConnectWallet;
