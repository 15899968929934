import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import MainSection from './components/MainSection';
import ConnectWallet from './components/ConnectWallet';
import BuyBHTS from './components/BuyBHTS';
import SellBHTS from './components/SellBHTS';
import { WalletProvider } from './context/WalletContext'; // WalletProvider import करें

function App() {
  return (
    <WalletProvider> {/* WalletProvider के साथ wrap करें */}
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<MainSection />} />
            <Route path="/connect-wallet" element={<ConnectWallet />} />
            <Route path="/buy-bhts" element={<BuyBHTS />} />
            <Route path="/sell-bhts" element={<SellBHTS />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </WalletProvider>
  );
}

export default App;
