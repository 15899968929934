import React from 'react';
import './SupportedWallets.css'; // Create this CSS file to style your supported wallets section
// import metamaskLogo from '../path-to-your-images/metamask.png';
// import tokenPocketLogo from '../path-to-your-images/tokenpocket.png';
// import trustWalletLogo from '../path-to-your-images/trustwallet.png';

const SupportedWallets = () => {
  return (
    <section className="supported-wallets-section">
      <h2>Supported Wallets</h2>
      <div className="wallets-container">
        <div className="wallet-card">
          <img src="assets/MM.jpg" alt="Metamask" />
      
        </div>
        <div className="wallet-card">
          <img src="assets/TP.jpg" alt="Token Pocket" />
        
        </div>
        <div className="wallet-card">
          <img src="assets/TW.jpg" alt="Trust Wallet" />
         
        </div>
      </div>
    </section>
  );
};

export default SupportedWallets;
