import React from 'react';
import './TimelineSection.css'; // Create this CSS file to style your timeline section

const TimelineSection = () => {
  return (
    <section className="timeline-section">
      <div className="timeline">
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>March - 2024</h3>
            <p>Thinking of a concept to enjoy and utilize national and international products and services under one window.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>April - 2024</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>May - 2024</h3>
            <p>Engaging with the companies to develop an ecosystem to bring all platforms under one roof.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>June - 2024</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>July - 2024</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>Aug - 2024</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>Oct - 2024</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>Dec - 2024</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>Jan - 2025</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>Feb - 2025</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>November - 2025</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
        <div className="timeline-event">
          <div className="timeline-content">
            <h3>Jan - 2026</h3>
            <p>Finding and locating the manufacturers and service providers of national and international level.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimelineSection;
