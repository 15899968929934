import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import './SellBHTS.css';
import { WalletContext } from '../context/WalletContext'; // Ensure correct path

const SellBHTS = () => {
  const { account } = useContext(WalletContext); // Get account from WalletContext
  const [bhtsBalance, setBhtsBalance] = useState('');
  const [livePrice, setLivePrice] = useState(''); // State for storing live price
  const [usdtAmount, setUsdtAmount] = useState(''); // State for USDT input
  const [bhtsAmount, setBhtsAmount] = useState(''); // State for BHTS amount
  const [loading, setLoading] = useState(false);
  const [transactionInProgress, setTransactionInProgress] = useState(false);
  const [apiTransactionComplete, setApiTransactionComplete] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');// State for storing popup messages
  const tokenContractAddress = "0xFDdFAd202D3226cCEa20DE2743ED9c4C641e6427"; // BHTS contract address on BSC
  const ownerAddress = "0xc66223405906FC203D2a56E3939b201696f577Ef"; // Replace with the owner's address
  const bscChainId = '0x38'; // BSC Mainnet Chain ID in hex (56 in decimal)
  const bscRpcUrl = 'https://bsc-dataseed.binance.org/'; // BSC Mainnet RPC URL

  useEffect(() => {
    const fetchLivePrice = async () => {
      try {
        const response = await axios.post('https://bighitterss.io/ProcessAPIwithK.aspx', {
          reqtype: 'livepriceapi',
        });
        console.log('Live Price API Response:', response.data); // Debugging line
        setLivePrice(response.data.rate); // Update state with the live price
      } catch (error) {
        console.error('Error fetching live price:', error.message);
      }
    };

    fetchLivePrice(); // Fetch live price immediately when component mounts
  }, []);

  useEffect(() => {
    const fetchBalances = async () => {
      if (account) {
        console.log('Account:', account); // Debugging: Verify account is set
        try {
          const response = await axios.post('https://bighitterss.io/ProcessAPIwithK.aspx', {
            reqtype: 'balanceapi',
            walletaddress: account,
            tokencontractaddress: tokenContractAddress,
          });
          console.log('API Response:', response.data); // Debugging line
          const balance = response.data.Balance;
          setBhtsBalance(balance !== undefined ? balance.toString() : '0'); // Ensure balance is a string
        } catch (error) {
          console.error('Error fetching balance:', error.message);
          setBhtsBalance('0'); // Fallback in case of error
        }
      }
    };

    fetchBalances(); // Fetch balance immediately when account changes
  }, [account]);

  // Calculate USDT amount based on entered BHTS amount
  useEffect(() => {
    if (bhtsAmount && livePrice) {
      const calculatedUsdtAmount = (parseFloat(bhtsAmount) * parseFloat(livePrice)).toFixed(4);
      setUsdtAmount(calculatedUsdtAmount);
    } else {
      setUsdtAmount('');
    }
  }, [bhtsAmount, livePrice]);

  // Handle input change for BHTS amount
  const handleBhtsChange = (e) => {
    const inputValue = e.target.value;
    // Ensure the input does not exceed the user's BHTS balance
    if (parseFloat(inputValue) > parseFloat(bhtsBalance)) {
      alert('Entered amount exceeds your BHTS balance');
    } else {
      setBhtsAmount(inputValue);
    }
  };

  // Ensure the user is connected to Binance Smart Chain
  const ensureBscNetwork = async (web3) => {
    const currentChainId = await web3.eth.getChainId();
    if (currentChainId !== parseInt(bscChainId, 16)) { // Convert hex chain ID to decimal
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: bscChainId }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          // The BSC network is not added to MetaMask, so we try to add it
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: bscChainId,
                  chainName: 'Binance Smart Chain Mainnet',
                  rpcUrls: [bscRpcUrl],
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://bscscan.com'],
                },
              ],
            });
          } catch (addError) {
            console.error('Failed to add BSC network to MetaMask:', addError);
            throw addError;
          }
        } else {
          console.error('Failed to switch to BSC network:', switchError);
          throw switchError;
        }
      }
    }
  };

  // Handle the "Sell BHTS Token" button click
  const handleSellBhts = async () => {
    if (!usdtAmount || parseFloat(usdtAmount) <= 0) {
      setPopupMessage('Please enter a valid BHTS amount.');
      setTimeout(() => setPopupMessage(''), 4000);
      return;
    }
    if (parseFloat(bhtsAmount) <= 0) {
      setPopupMessage('Please enter a valid amount to sell.');
      setTimeout(() => setPopupMessage(''), 4000);
      return;
    }
  
    if (parseFloat(bhtsAmount) > parseFloat(bhtsBalance)) {
      setPopupMessage('Entered amount exceeds your BHTS balance.');
      return;
    }
  
    if (window.ethereum) {
      setLoading(true); // Show loader
      setTransactionInProgress(true); // Mark transaction as in progress
      setApiTransactionComplete(false); // Initialize API transaction status
  
      const web3 = new Web3(window.ethereum);
      try {
        // Ensure the user is on the Binance Smart Chain
        await ensureBscNetwork(web3);
  
        // Request account access if needed
        await window.ethereum.request({ method: 'eth_requestAccounts' });
  
        const accounts = await web3.eth.getAccounts();
        const sender = accounts[0];
  
        // Initialize contract instance
        const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenContractAddress); 
  
        // Convert BHTS amount to Wei
        const bhtsAmountInWei = web3.utils.toWei(bhtsAmount, 'ether'); 
  
        // Prepare transaction data
        const txData = tokenContract.methods.transfer(ownerAddress, bhtsAmountInWei).encodeABI();
  
        // Estimate gas limit
        const gasLimit = await web3.eth.estimateGas({
          from: sender,
          to: tokenContractAddress,
          data: txData,
        });
  
        // Fetch current gas price
        const gasPrice = await web3.eth.getGasPrice();
  
        // Construct the transaction object
        const tx = {
          from: sender,
          to: tokenContractAddress,
          data: txData,
          gas: gasLimit,
          gasPrice: gasPrice,
        };
  
        // Send transaction
        const txHash = await web3.eth.sendTransaction(tx);
        const txReceipt = await web3.eth.getTransactionReceipt(txHash.transactionHash);
  
        if (txReceipt.status) {
          // Call the API to process the transaction
          const payoutResponse = await axios.post('https://bighitterss.io/ProcessAPIwithK.aspx', {
            reqtype: 'singlepayoutapi',
            to: sender,
            amount: usdtAmount,
            rate: livePrice,
            usdtamount: bhtsAmount,
            txHash: txHash.transactionHash,  // Include live price in the payout request
          });
  
          if (payoutResponse.data.Status) {
            setApiTransactionComplete(true); // Mark API transaction as complete
            setPopupMessage('Amount sent successfully');
  
            // Fetch and update the BHTS balance immediately after the successful transaction
            const balanceResponse = await axios.post('https://bighitterss.io/ProcessAPIwithK.aspx', {
              reqtype: 'balanceapi',
              walletaddress: sender,
              tokencontractaddress: tokenContractAddress,
            });
  
            const updatedBalance = balanceResponse.data.Balance;
            setBhtsBalance(updatedBalance !== undefined ? updatedBalance.toString() : '0'); // Update balance state
  
            // Reset the BHTS amount and USDT amount fields to 0
            setBhtsAmount(''); // Reset the BHTS amount field to 0
            setUsdtAmount('');
            setTimeout(() => {
              setPopupMessage('');
            }, 4000); // Reset USDT amount field
          } else {
            setPopupMessage('Transaction failed at API level.');
          }
        } else {
          setPopupMessage('Transaction failed.');
        }
      } catch (error) {
        console.error('Error during transaction:', error.message);
        setPopupMessage('An error occurred during the transaction');
      } finally {
        setLoading(false); // Hide loader
        setTransactionInProgress(false); // Mark transaction as not in progress
      }
    } else {
      setPopupMessage('MetaMask not detected');
    }
  };
  

  // Define ERC20 ABI (minimal)
  const ERC20_ABI = [
    // Only include the transfer method
    {
      constant: false,
      inputs: [
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
      ],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function',
    },
  ];

  return (
    <div className='row'>
      <div className='col-lg-4 col-sm-6 offset-lg-4 offset-sm-3'>
        <div className="gradient">
          <div className="card p-4 rounded-5 footer-wrap border-golden pt-5 pb-5 w-100 mb-0">
            <div className="bg-light mb-4">
              <div className="p-2 header-color">Sell BHTS</div>
            </div>
            <form>
              <div className="d-flex justify-content-between">
                <div className="coin-amount">
                  <b>BHTS: {bhtsBalance}</b> 
                </div>
                <div className="coin-amount">
                  {/* You can add additional info here if needed */}
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Live Price</label>
                <div className="input-group">
                  <span className="input-group-text rounded-0">
                    <img src="https://bighitterss.io/assets/img/resources/favicon.png" alt="" width={30} />
                  </span>
                  <input type="text" className="form-control" placeholder="1.1" value={livePrice} readOnly/>
                  <button type="button" className="btn btn-style-1 rounded-0">
                    USDT/BHTS
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Enter BHTS Token</label>
                <div className="input-group">
                  <span className="input-group-text rounded-0">
                    <img src="https://bighitterss.io/assets/img/resources/favicon.png" alt="" width={30} />
                  </span>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="0.0"
                    value={bhtsAmount}
                    onChange={handleBhtsChange}
                    style={{ width: "150px" }}
                  />
                  <button type="button" className="btn btn-style-1 rounded-0 ">
                   BHTS
                  </button>
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">USDT</label>
                <div className="input-group">
                  <span className="input-group-text rounded-0">
                    <img src="https://bighitterss.io/assets/img/resources/favicon.png" alt="" width={30} />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0"
                    value={usdtAmount} readOnly
                    style={{ width: "150px" }}
                  />
                  <button type="button" className="btn btn-style-1 rounded-0">
                    USDT
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                {/* <button type="button" className="btn btn-style-1">
                  Withdraw
                </button> */}
                <button type="button" className="btn btn-style-2" onClick={handleSellBhts} disabled={transactionInProgress}>
                  Sell BHTS Token
                </button>
              </div>
            </form>
            {loading && (
              <div className="loader-overlay">
                <div className="spinner"></div>
                <p>Processing...</p>
              </div>
            )}
            {popupMessage && (
              <div className="popup-message">{popupMessage}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellBHTS;
